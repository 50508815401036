import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { log } from 'lib/data/compute/ads/helpers';
import { AdsMeta, Meta } from 'lib/labrador/types';
import { getVersionNo } from 'lib/labrador/utils';
import Script from 'next/script';
import { isUndefined } from 'typesafe-utils';

export interface StandaloneAdTemplateProps extends StandaloneComponentProps {
  pageMeta?: Meta;
  adsData?: AdsMeta;
}

export const StandaloneAdTemplate: StandaloneComponent<StandaloneAdTemplateProps> = ({
  pageMeta = {},
  adsData = {},
}) => {
  const showAds = !(pageMeta?.isNativeAd || pageMeta?.hideAds);
  if (!showAds) {
    return null;
  }

  const preConfig = adsData.adsPreConfig || {};
  const demandManagerUrl = preConfig?.demandManagerUrl || 'https://micro.rubiconproject.com/prebid/dynamic/13012.js';
  const defaultEnableDemandManager = true;
  const enableDemandManager = isUndefined(preConfig?.enableDemandManager)
    ? defaultEnableDemandManager
    : preConfig.enableDemandManager === 'true';
  const isOcelotEnvProd = process.env.OCELOT_ENVIRONMENT === 'prod';
  const isAdsCdnProd = isOcelotEnvProd && process.env.OCELOT_AD_CDN_ENV !== 'stage';
  const latestAdsStable = `3.0.13`;
  const adsBundleProd = 'https://cdn.allermedia.se/se-aller-ads/prod/';
  const adsBundleStage = 'https://cdn-dev.allermedia.se/se-aller-ads/stage/';
  const basePath = isAdsCdnProd ? adsBundleProd : adsBundleStage;
  const adsManagerPath = `${basePath}${preConfig?.siteAdsBundleVersions?.bundleVersion || latestAdsStable}/aller-ads.bundle.min.js`;

  log('adsManagerPath', adsManagerPath);

  let loadedByAdsLib;
  const ocelotLoad = ['/2.', '/1.'].some((majorVersion) => adsManagerPath.includes(majorVersion));
  if (!ocelotLoad) {
    loadedByAdsLib = {
      demandManager: true,
      gpt: false,
      adnami: false,
      config: true,
    };
  }

  const currentAdsScriptVersion = getVersionNo(adsManagerPath);
  const variant = '07';
  const cacheBuster = `v=${currentAdsScriptVersion}-${variant}`;
  const demandManagerPath = `${demandManagerUrl}?${cacheBuster}`;
  const adnamiPath = 'https://macro.adnami.io/macro/spec/adsm.macro.747590aa-4758-4281-839e-4b9b65def16a.js';

  const adsPath = `/common/js/ads`;

  const gptPath = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';

  const adsUniqueId = adsData.adsUniqueId || '';
  const topAdTarget = `top_ad-${adsUniqueId}`;

  log('#### showAds', showAds, pageMeta?.hideAds);

  const adsConf: string = JSON.stringify({
    pageMeta,
    adsData,
    preConfig,
    siteName: process.env.OCELOT_SITE,
    topAdTarget,
    loadedByAdsLib,
  });

  return (
    <>
      {/* Adnami high impact formats */}
      {!loadedByAdsLib?.adnami && <Script src={`${adsPath}/adnami-conf.js`} strategy="beforeInteractive" />}
      {!loadedByAdsLib?.adnami && <Script id="adnami-ads-formats" src={`${adnamiPath}`} strategy="beforeInteractive" />}

      {/* GoogleAdsManager */}
      {!loadedByAdsLib?.gpt && <Script id="gpt-initiator" src={`${gptPath}`} strategy="beforeInteractive" />}

      {/* Global ad configuration */}
      {!loadedByAdsLib?.config && (
        <Script
          id="ads-global-conf"
          src={`${adsPath}/global-conf.js?${cacheBuster}`}
          strategy="beforeInteractive"
          data-ads-conf={adsConf}
        />
      )}

      {/* Prebid */}
      {!loadedByAdsLib?.demandManager && enableDemandManager && (
        <Script id="demand-manager" referrerPolicy="strict-origin-when-cross-origin" src={demandManagerPath} />
      )}

      {/* AdsLibrary */}
      <Script
        id="ads-manager"
        src={`${adsManagerPath}?${cacheBuster}`}
        strategy={loadedByAdsLib ? 'beforeInteractive' : 'afterInteractive'}
        {...(loadedByAdsLib ? { 'data-ads-conf': adsConf } : {})}
      />
    </>
  );
};
